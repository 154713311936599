import styled from "styled-components";


export const MainWrapper = styled.div`
  position: relative;
  border: 1px solid #ccc;
  height: 100%;
  display: flex;
  overflow: hidden; /* Prevent content from overflowing */
`;

export const PDFBackgroundWrapper = styled.div`
  background-color: white;
  position: relative; 
  width: 100%
`;


export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;


export const HeaderTitle = styled.h4`
`;

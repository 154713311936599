import { useEffect, useState, useRef } from "react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import { 
    MainWrapper, 
    BackspaceContainer, 
    HeaderWrapper, 
    HeaderTitleWrapper, 
    HeaderTitle,
    EditorWrapper,
    LoaderContainer
} from "./ReviewFormatPage.styled";
import speechFormat from "../../helpers/speechFormat";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import { generateStyledDocument } from "../../apis/ApiServerRequests";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import SuccessICF from "../../modules/SuccessICF/SuccessICF";


const ReviewFormatPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { requiredData, data } = location.state || {};
    const [fileData, setFileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [generated, setGenerated] = useState(false);

    const DEFAULT_URL = "https://dms-onlyoffice.agilewriter.smarter.codes";
    const ONLYOFFICE_URL = process.env.REACT_APP_ONLYOFFICE_URL
        ? process.env.REACT_APP_ONLYOFFICE_URL
        : DEFAULT_URL;

    var onDocumentReady = function (event) {
        console.log("Document is loaded");
    };

    var onLoadComponentError = function (errorCode, errorDescription) {
        switch (errorCode) {
            case -1: // Unknown error loading component
                console.log(errorCode, errorDescription);
                break;

            case -2: // Error load DocsAPI from http://documentserver/
                console.log(errorCode, errorDescription);
                break;

            case -3: // DocsAPI is not defined
                console.log(errorCode, errorDescription);
                break;
        }
    };

    

    useEffect(() => {
        if (requiredData && data && !loading) {
            setLoading(true);
            const getDocumentfunction = (requiredData, data) => {
                generateStyledDocument(data, requiredData)
                .then(({ data }) => {
                    setFileData(data)
                    setLoading(false);
                })
                .catch(() => {
                    alert("Something went wrong. Try again later.");
                });
            } 
            getDocumentfunction(requiredData, data)
        }
    }, [requiredData, data])

    


    const editorRef = useRef(null);

    useEffect(() => {
        if (editorRef.current) {
            // Access the Document Editor API for customizations
            const editorInstance = editorRef.current;

            // Add a custom button to the toolbar
            editorInstance.setToolbar({
                items: [
                    {
                        type: "button",
                        text: "Custom Icon",
                        iconUrl: "path-to-your-custom-icon.png", // URL for your custom icon
                        onClick: () => {
                            alert("Custom button clicked!");
                            // Add any custom action here
                        }
                    },
                    // Add more toolbar items as needed
                ]
            });
        }
    }, [editorRef]);


    const onBack = () => {
        navigate("/");
    };


    const saveDocument = () => {
        setLoading(true)
        const event = new KeyboardEvent('keydown', {
          bubbles: true,
          cancelable: true,
          key: 's',
          ctrlKey: true,
        });
      
        document.dispatchEvent(event);
        setTimeout(() => {
            setLoading(false)
            setGenerated(true);
        }, 12000);
    };

    const handleGenerated = (val) => {
        navigate('/format')
    };


    return (
        <MainWrapper>
            {loading && (
                <SuccessPopup
                    msg_processing={"Generating Doc, please wait..."}
                    onClose={() => { }}
                    loading={true}
                />
            )}
            {
                generated && !loading && <SuccessICF
                    handleGenerated={handleGenerated}
                    docTitle={fileData?.final_filename}
                    totalMapping={21}
                    resolvedMapping={21}
                ></SuccessICF>
            }
            { !generated &&
                <>
                    <HeaderWrapper>
                    <BackspaceContainer>
                        <Button
                            icon={<BackspaceIcon />}
                            type={"ghost"}
                            accessKeyText={"[BACKSPACE]"}
                            onClick={onBack}
                            iconStyle={{ marginRight: "4px" }}
                            data-speech={speechFormat({
                                key: "Backspace",
                                action: "go back",
                                item: "to Dashboard",
                            })}
                            buttonStyle={{ paddingTop: "0px" }}
                        />

                    </BackspaceContainer>
                    <HeaderTitleWrapper>
                        <HeaderTitle>REVIEW FORMATTING</HeaderTitle>
                        <Button
                            buttonStyle={{ marginLeft: "6px", marginRight: "10px" }}
                            type="brand"
                            text="Format Doc"
                            accessKeyText="[F]"
                            onClick={() => saveDocument()}
                            data-speech={speechFormat({
                                key: "F",
                                action: "",
                                item: "Format Doc",
                            })}
                        />
                    </HeaderTitleWrapper>

                    </HeaderWrapper>
                    <EditorWrapper>
                        {fileData && <DocumentEditor
                            ref={editorRef}
                            id="docxEditor"
                            documentServerUrl={ONLYOFFICE_URL}
                            config={{
                                "document": {
                                    "fileType": "docx",
                                    "info": {},
                                    "key": "wehfsf345",
                                    "permissions": {},
                                    "title": fileData?.comparison_filename,
                                    "url": fileData?.public_url
                                },
                                "documentType": "word",
                                "editorConfig": {
                                    "callbackUrl": "https://api-dpai.agilewriter.smarter.codes/callback?file=something",
                                    "customization": {
                                        "anonymous": {
                                            "request": true,
                                            "label": "Guest"
                                        },
                                        "autosave": true,
                                        'search': false,
                                        'chat': false,
                                        'headings': false,
                                        'about': false,
                                        'reviewDisplay': false,
                                        "toolbar": true,
                                        "comments": false,
                                        "compactHeader": true,
                                        "compactToolbar": true,
                                        "compatibleFeatures": false,
                                        "forcesave": true,
                                        "help": false,
                                        "hideRightMenu": true,
                                        "hideRulers": true,
                                        "integrationMode": "embed",
                                        "macros": false,
                                        "macrosMode": "Warn",
                                        "mentionShare": false,
                                        "mobileForceView": true,
                                        "plugins": true,
                                        "toolbarHideFileName": false,
                                        "toolbarNoTabs": true,
                                        "uiTheme": "theme-light",
                                        "unit": "cm",
                                        "zoom": 100
                                    }
                                },
                                "height": "620px",
                                "token": fileData?.token,
                                "width": "100%",
                                "type": "desktop"
                            }
                            }
                            events_onDocumentReady={onDocumentReady}
                            onLoadComponentError={onLoadComponentError}
                        />}
                    </EditorWrapper>
                </>
            }
        </MainWrapper>
    );
};

export default ReviewFormatPage;
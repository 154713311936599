import React, { useState, useRef, useEffect } from 'react';
import { 
    MainWrapper, 
    CommentContainer, 
    PaginationContainer, 
    OptionContainer,
    CommentText,
    TitleContainer,
    ListTitle
} from './PdfCommentPanel.styled';
import Button from '../../components/Button/Button';
import speechFormat from '../../helpers/speechFormat';
import Pagination from '../../components/Pagination/Pagination';
import AcceptIcon from '../../assets/svgs/AcceptIcon';
import RejectIcon from '../../assets/svgs/RejectIcon';
import { useTheme } from 'styled-components';
import useKeyPress from '../../hooks/useKeyPress';
import useSpeechSynthesis from '../../hooks/useSpeechSynthesis';

const PdfCommentPanel = ({ comments, selectedCommentId, onCommentClick, setCommentPosition, setPageNumber, ...props }) => {
    const theme = useTheme();
    const { speak, speaking, cancelSpeaking } = useSpeechSynthesis(false);
    const [reading, setReading] = useState(false);

    const commentRefs = useRef([]);

    const [selectedItem, setSelectedItem] = useState(null);

    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio,
      });
    
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            devicePixelRatio: window.devicePixelRatio,
          });
        };
    
        // Attach the resize event listener
        window.addEventListener('resize', handleResize);
        
        // Attach listener for zoom (devicePixelRatio changes)
        const handleZoom = () => {
          setWindowSize(prev => ({
            ...prev,
            devicePixelRatio: window.devicePixelRatio,
          }));
        };
        
        window.addEventListener('resize', handleZoom); // Resize affects zoom as well
    
        return () => {
          // Clean up the event listeners
          window.removeEventListener('resize', handleResize);
          window.removeEventListener('resize', handleZoom);
        };
      }, []);

    const handleCommentClick = (id) => {
        setSelectedItem(id);
    };

    const handleToggle = () => {
        let comment = itemList.find((item, index) => index === selectedItem)
        onCommentClick(comment, !comment?.status)
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        onCommentClick()
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
        onCommentClick()
    };

    useEffect(() => {
        if (comments && comments.length > 0) {
            // Group comments by their page
            const groupedByPage = comments.reduce((acc, comment) => {
                if (!acc[comment.page]) {
                    acc[comment.page] = [];
                }
                acc[comment.page].push(comment);
                return acc;
            }, {});
    
            // Get all unique page numbers
            const pages = Object.keys(groupedByPage);
    
            // Get the comments for the current page
            const currentPageNumber = pages[currentPage - 1];  // Adjust index for currentPage

            const activeList = groupedByPage[currentPageNumber] || [];
    
            // Set the current page number
            setPageNumber(activeList[0].page);
    
            // Set the active list of comments for the current page
            setItemList(activeList);
    
            // Set total pages based on the number of distinct page numbers
            setTotalPages(pages.length);
        }
    }, [currentPage, comments, windowSize]);

    const handleDocsListRead = () => {
        if (reading) {
          cancelSpeaking();
          setReading(false);
          return;
        }
        let speech = "";
        itemList.forEach(({ status, type }, i) => {
          speech += speechFormat({
            key: `Control + ${i + 1}`,
            action: !status ? "Accept": "Reject",
            item: type,
          });
          speech += ". ";
        });
        setReading(true);
        speak(speech);
    };

    useKeyPress("R", [], handleDocsListRead);

    useKeyPress("z", [], handleToggle);
    useKeyPress("1", ["ctrlKey"], () => setSelectedItem(0));
    useKeyPress("2", ["ctrlKey"], () => setSelectedItem(1));
    useKeyPress("3", ["ctrlKey"], () => setSelectedItem(2));
    useKeyPress("4", ["ctrlKey"], () => setSelectedItem(3));
    useKeyPress("5", ["ctrlKey"], () => setSelectedItem(4));
    useKeyPress("6", ["ctrlKey"], () => setSelectedItem(5));
    useKeyPress("7", ["ctrlKey"], () => setSelectedItem(6));



    useEffect(() => {
    
        const timeoutId = setTimeout(() => {
            const positions = commentRefs.current.map((ref) => {
                if (ref) {
                    const borderColor = ref.getAttribute("bordercolor");
                    const commentId = ref.getAttribute("id");
                    const rect = ref.getBoundingClientRect();
                    return { 
                        id: commentId,
                        x: rect.left, 
                        y: rect.top + 2,
                        borderColor: borderColor
                    };
                }
                return null;
            }).filter(pos => pos !== null);
            
            setCommentPosition(positions.sort((a, b) => a.y - b.y));
        }, 100);
    
        return () => clearTimeout(timeoutId); // Clean up the timeout on unmount
    }, [itemList, setCommentPosition]);

    const timeoutId = useRef(null); // Use useRef to store the timeout ID
    const itemHeight = 60; // Define the height of each comment item (adjust as necessary)

    useEffect(() => {
        const handleScroll = () => {
            clearTimeout(timeoutId.current);
    
            timeoutId.current = setTimeout(() => {
                const commentDiv = document.getElementById("commentDiv");
                const scrollTop = commentDiv.scrollTop;
    
                // Calculate the index of the first visible item
                const firstVisibleIndex = Math.floor(scrollTop / itemHeight);
                const lastVisibleIndex = firstVisibleIndex + 7; // Show 7 items total
    
                // Set positions for the visible range of comments
                const positions = [];
    
                for (let i = 0; i <= lastVisibleIndex; i++) {
                    const index = firstVisibleIndex + i;
    
                    if (index < itemList.length + 1) {
                        const ref = commentRefs.current[index];
    
                        if (ref) {
                            const borderColor = ref.getAttribute("bordercolor");
                            const commentId = ref.getAttribute("id");
                            const rect = ref.getBoundingClientRect();
    
                            // If ref exists, calculate its position
                            const position = {
                                id: commentId,
                                x: rect.left,
                                y: rect.top + 2,
                                borderColor: borderColor,
                            };
                            positions.push(position);
                        } 
                    } 
                }
    
                // Only set positions if they were calculated successfully
                if (positions.length > 0) {
                    setCommentPosition(positions);
                }
            }, 100); // Adjust the delay as needed
        };
    
        const commentDiv = document.getElementById("commentDiv");
        commentDiv.addEventListener("scroll", handleScroll);
    
        return () => {
            clearTimeout(timeoutId.current);
            commentDiv.removeEventListener("scroll", handleScroll);
        };
    }, [itemList, setCommentPosition]);

    function formatString(input) {
        const parts = input.match(/PPD|[A-Z][a-z]*/g);
        
        if (parts[parts.length - 1] === 'Strategy') {
            parts.pop();
        }
        return parts.join(' ');
    }

    return (
        <>
        <TitleContainer>
            <ListTitle>Action & pagination shortcuts <br />Only works for the selected rows</ListTitle>
            <Button
              disabled={false}
              text={reading ? "Cancel Read List" : "Read List"}
              type={"ghost"}
              accessKeyText={"[R]"}
              onClick={handleDocsListRead}
              data-speech={speechFormat({
                key: "R",
                action: "read",
                item: "Doc",
              })}
            />
          </TitleContainer>
            <MainWrapper  id="commentDiv" {...props}>
                {itemList.map((comment, index) => (
                    <CommentContainer
                        key={comment.id}
                        ref={(el) => (commentRefs.current[comment.id] = el)}
                        id={comment.id}
                        onClick={() => handleCommentClick(index)}
                        backgroundColor={selectedItem === index ? "#72dfab" : (comment?.status ? "#2E986633" : "initial")}
                        borderColor={comment?.status ? theme.color_theme.primaryColor : "#ccc"}
                    >
                        <div>
                            <CommentText>{formatString(comment.type)}</CommentText>
                            <Button
                                type="ghost"
                                text={comment?.status ? "Accepted" : "Rejected"}
                                subText={true}
                                onClick={() => setPageNumber(comment.page)}
                                accessKeyText={`[ Ctrl + ${index + 1} ]`
                                }
                                buttonStyle={{ padding: "4px 0px", width: "fit-content" }}
                                textStyle={{
                                    fontStyle: 0 ? "italic" : "inherit",
                                    ...(comment?.status && { color: theme.color_theme.primaryColor }),
                                    opacity: comment?.status ? 1 : 'none'
                                }}
                                data-speech={speechFormat({
                                    key:
                                        1 ? "Z" : `Control + ${index + 1}`,
                                    action: 1 ? "download" : "select",
                                    item: 1 ? "text" : "text",
                                })}
                            />
                        </div>
                        <OptionContainer
                            onClick={() => onCommentClick(comment, !comment?.status)}
                        >
                            {comment?.status ? <RejectIcon /> : <AcceptIcon />}
                            <p>{`[z]`}</p>
                        </OptionContainer>

                    </CommentContainer>
                ))}

            </MainWrapper>
            <PaginationContainer>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onFirstPage={() => setCurrentPage(1)}
                    onLastPage={() => setCurrentPage(totalPages)}
                    onPrev={handlePrevPage}
                    onNext={handleNextPage}
                    firstAccessKey={{
                        text: "[;]",
                        key: ";",
                        subkey: [],
                        speech: speechFormat({
                            key: "Semicolon",
                            action: "goto",
                            item: "First page of Redaction List",
                        }),
                    }}
                    prevAccessKey={{
                        text: "[,]",
                        key: ",",
                        subkey: [],
                        speech: speechFormat({
                            key: "comma",
                            action: "goto",
                            item: "Previous page of Redaction List",
                        }),
                    }}
                    nextAccessKey={{
                        text: "[.]",
                        key: ".",
                        subkey: [],
                        speech: speechFormat({
                            key: "fullstop",
                            action: "goto",
                            item: "Next page of Redaction List",
                        }),
                    }}
                    lastAccesskey={{
                        text: "[']",
                        key: "'",
                        subkey: [],
                        speech: speechFormat({
                            key: "single quote",
                            action: "goto",
                            item: "Last page of Redaction List",
                        }),
                    }}
                />
            </PaginationContainer>
        </>
    );
};

export default PdfCommentPanel
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    BackspaceContainer,
    Wrapper,
    FormBody,
    FormContainer,
    FormTitle,
    FormFooter
} from "./RedactDoc.styled";
import Button from "../../components/Button/Button";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import speechFormat from "../../helpers/speechFormat";
import UploadDoc from "../../components/UploadDoc/UploadDoc";
import { uploadRedactDocument, uploadRedactionInputDoc } from "../../apis/ApiServerRequests";
import DropDownNew from "../../components/Dropdown/Dropdown";
import DocumentIcon from "../../assets/svgs/DocumentIcon";
import SelectProtoDocIcon from "../../assets/svgs/SelectProtoDocIcon";
import DownIcon from "../../assets/svgs/DownIcon";
import SelectIcfIcon from "../../assets/svgs/SelectIcfIcon";
import CompanyBadgeIcon from "../../assets/svgs/CompanyBadgeIcon";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";
import SuccessICF from "../SuccessICF/SuccessICF";
import useKeyPress from "../../hooks/useKeyPress";
import InputField from "../../components/InputField/InputField";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import ProtocolModal from "../ProtocolModal/ProtocolModal";

const RedactDoc = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [inputDocument, setInputDocument] = useState(null)
    const [inputKeywordFile, setInputKeywordFile] = useState(null)
    const [selectedPDFDoc, setSelectedPDFDoc] = useState(null);
    const [selectedKeywordFile, setSelectedKeywordFile] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);
    const [redactionType, setRedactionType] = useState("redact")
    const [removeMetadata, setRemoveMetadata] = useState("True");
    const [progress, setProgress] = useState({
        filename: "",
        total_mappings: 21,
        mappings_processed: 0,
        estimated_time: 5,
    });
    const [generated, setGenerated] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [selectedPharma, setSelectedPharma] = useState("");
    const [templateType, setTemplateType] = useState("redact_file");


    const handleGenerated = (val) => {
        setGenerated(false)
        setInputDocument(null)
        setSelectedKeywordFile(null)
        setInputKeywordFile(null)
        setSelectedPDFDoc(null)
        setSelectedPharma("")
        setTemplateType("redact_file")
        setProgress({
            filename: "",
            total_mappings: 21,
            mappings_processed: 0,
            estimated_time: 5,
        })
    };


    const onBack = () => {
        navigate("/");
    };

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        
        if (inputDocument) {
            formData.append("file", inputDocument);
            const response = await uploadRedactionInputDoc(selectedPharma, formData)
            console.log("response from file upload ==.", response)
        }
        uploadRedactDocument(selectedPharma, selectedPDFDoc).then((response) => {
            console.log("response ==>", response.data)
            navigate(`/review/pdf/${response.data}`)
            // setDownloadUrl(response.data.public_url)
            // setProgress({
            //     filename: response.data.filename,
            //     total_mappings: 21,
            //     mappings_processed: 0,
            //     estimated_time: 5,
            // })
            setLoading(false)
            // setGenerated(true)
        })
            .catch((err) => {
                setLoading(false)
            })
    };

    const handleInputFile = async (template_type, file) => {
        if (template_type === "redact_file") {
            setInputDocument(file)
        }

        if (template_type === 'redact_keyword_file') {
            setInputKeywordFile(file)
        }
    }

    const openModal = (templateType) => {
        setTemplateType(templateType)
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    const selectDocFile = (filename) => {

        if (templateType === "select_pharmacy") {
            setSelectedPharma(filename);
        } else if (templateType === "redact_keyword_file")
            setSelectedKeywordFile(filename)
        else {
            setSelectedPDFDoc(filename)
        }
    }

    useKeyPress("G", ["altKey"], handleSubmit);
    useKeyPress("1", ["altKey"], () => openModal("select_pharmacy"));
    useKeyPress("2", ["altKey"], () => selectedPharma.length && openModal("redact_file"));
    useKeyPress("3", ["altKey"], () => selectedPharma.length && openModal("redact_keyword_file"));
    useKeyPress("backspace", [], () => onBack());

    return (
        <Wrapper>
            <BackspaceContainer>
                <Button
                    icon={<BackspaceIcon />}
                    type={"ghost"}
                    accessKeyText={"[BACKSPACE]"}
                    onClick={onBack}
                    iconStyle={{ marginRight: "4px" }}
                    data-speech={speechFormat({
                        key: "Backspace",
                        action: "go back",
                        item: "to Dashboard",
                    })}
                />
            </BackspaceContainer>
            {generated && !loading && <SuccessICF
                handleGenerated={handleGenerated}
                docTitle={progress?.filename}
                totalMapping={progress?.total_mappings}
                resolvedMapping={progress?.mappings_processed}
            ></SuccessICF>}

            {!generated && <FormContainer>
                <FormTitle>Redact Document</FormTitle>

                <FormBody>

                    <InputField
                        width={"420"}
                        readonly={true}
                        onClick={() => openModal("select_pharmacy")}
                        onChangefunction={() => openModal("select_pharmacy")}
                        rightIcon={DownIcon()}
                        inputCommand={{
                            text: "[Alt + 1]",
                            key: "1",
                            subkey: ["altKey"],
                        }}
                        value={selectedPharma}
                        labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                        }}
                        label={"Select the Company"}
                        inputStyle={{ paddingBottom: "10px", height: "34px" }}
                        icon={CompanyBadgeIcon()}
                        data-speech={speechFormat({
                            key: "Alt + 1",
                            action: "",
                            item: "Select the Company",
                        })}
                    />

                    <InputField
                        width={"420"}
                        readonly={true}
                        disabled={true}
                        onClick={() => selectedPharma && openModal("redact_file")}
                        onChangefunction={() => selectedPharma && openModal("redact_file")}
                        rightIcon={DownIcon()}
                        inputCommand={{
                            text: "[Alt + 2]",
                            key: "2",
                            subkey: ["altKey"],
                        }}
                        value={selectedPDFDoc}
                        labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                        }}
                        label={"Select the Input PDFs"}
                        inputStyle={{
                            paddingBottom: "10px",
                            height: "34px",
                            cursor: selectedPharma ? "" : "not-allowed",
                        }}
                        icon={SelectIcfIcon()}
                        data-speech={speechFormat({
                            key: "Alt + 2",
                            action: "",
                            item: "Select the Company",
                        })}
                    />

                    {/* <InputField
                        width={"420"}
                        readonly={true}
                        disabled={true}
                        onClick={() => selectedPharma && openModal("redact_keyword_file")}
                        onChangefunction={() => selectedPharma && openModal("redact_keyword_file")}
                        rightIcon={DownIcon()}
                        inputCommand={{
                            text: "[Alt + 3]",
                            key: "3",
                            subkey: ["altKey"],
                        }}
                        value={selectedKeywordFile}
                        labelStyle={{
                            position: "absolute",
                            padding: "4px 50px",
                        }}
                        label={"Select redaction keywords"}
                        inputStyle={{
                            paddingBottom: "10px",
                            height: "34px",
                            cursor: selectedPharma ? "" : "not-allowed",
                        }}
                        icon={SelectIcfIcon()}
                        data-speech={speechFormat({
                            key: "Alt + 3",
                            action: "",
                            item: "Select redaction keywords",
                        })}
                    /> */}

                    {/* <DropDownNew
                        width={"420"}
                        label={`Select Redaction Type`}
                        labelStyle={{
                            position: "absolute",
                            padding: "3px 20px",
                        }}
                        options={["Redact", "Redaction preview"]}
                        defaultText={`Redact`}
                        onChangefunction={(value) =>
                            setRedactionType(value)
                        }
                        icon={SelectProtoDocIcon()}
                        inputCommand={{
                            text: "[Alt + 4]",
                            key: "4",
                            subkey: ["altKey"],
                        }}
                    /> */}

                    {/* <DropDownNew
                        width={"420"}
                        label={`Remove file metadata?`}
                        labelStyle={{
                            position: "absolute",
                            padding: "3px 20px",
                        }}
                        options={["Yes", "No"]}
                        defaultText={"Yes"}
                        onChangefunction={(value) =>
                            setRemoveMetadata(value)
                        }
                        inputCommand={{
                            text: "[Alt + 5]",
                            key: "5",
                            subkey: ["altKey"],
                        }}
                    /> */}





                </FormBody>

                <FormFooter>
                    {!loading ? (
                        <Button
                            disabled={(inputDocument || selectedPDFDoc) ? false : true}
                            text={"Redact Doc"}
                            type={"brand"}
                            accessKeyText={"[Alt + G]"}
                            onClick={handleSubmit}
                            data-speech={speechFormat({
                                key: "Alt + G",
                                action: "generate",
                                item: "Doc",
                            })}
                        />
                    ) : (
                        <Button text={"Generating..."} type={"secondary"} disabled />
                    )}
                </FormFooter>
            </FormContainer>}

            {modalOpen && (
                <ModalWrapper
                    isOpen={modalOpen}
                    onClose={closeModal}
                    overlayName={templateType}
                >
                    <ProtocolModal
                        overlayName={templateType}
                        onClose={closeModal}
                        templateType={templateType}
                        enablePreview={false}
                        title={
                            templateType === "select_pharmacy" 
                            ? "Select the company" 
                            : (templateType === "redact_file") ?  "Select the Input PDF"
                            : "Select redaction keywords"}
                        setSelectedInputFile={handleInputFile}
                        selectDocFile={selectDocFile}
                        acceptedFileType={templateType === "redact_file" ? ".pdf": ".txt"}
                        pharmaName={selectedPharma}
                    />
                </ModalWrapper>)}

            {loading && (
                <SuccessPopup
                    msg_processing={"Generating Doc, please wait..."}
                    onClose={() => { }}
                    loading={true}
                />
            )}
        </Wrapper>
    )
}


export default RedactDoc;


